"use client";

import React, { ReactElement, ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";

import { MhcStatUnitEnum } from "common/util/types";
import {
  MhcStatIdentifierFragment,
  MhcTimeSeries,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";

import { sendGaNavigationEvent } from "common/util/googleAnalytics";
import { toHtmlId } from "common/util/helpers";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import { getStartAndEndDatesFromTimeSeries } from "modules/Flu/util/fetchingFunctions/getStartAndEndDatesFromTimeSeries";

import KpiAgeAdjustedPopover from "common/components/KPI/KpiAgeAdjustedPopover";
import { TrendlineProps } from "../charts/Trendline";
import KpiAdditionalInformation from "./KpiAdditionalInformation";
import KpiAdditionalVisualization, {
  AdditionalVisualizationProps
} from "./KpiAdditionalVisualization";
import KpiDatePeriod from "./KpiDatePeriod";
import KpiGeography from "./KpiGeography";
import KpiLink from "./KpiLink";
import { KpiPercentSentence, KpiPercentSentenceProps } from "./KpiPercentSentence";
import KpiSuppression from "./KpiSuppression";
import { KpiTarget, KpiTargetProps } from "./KpiTarget";
import KpiTitle, { isAgeAdjusted } from "./KpiTitle";
import { KpiValueSize } from "./KpiValue";
import KpiValueAndTrend, { KpiValueAndTrendProps } from "./KpiValueAndTrend";
import KpiWrapper from "./KpiWrapper";

export type KpiSize = "large" | "medium" | "small";

export interface EnhancedKpiProps {
  additionalVisualizationProps?: AdditionalVisualizationProps;
  date?: string | null;
  dataDatePeriod?: string | null;
  /** @deprecated - use locationName instead */
  geography?: string | null;
  locationName?: string | null;
  precision?: number;
  isPercent?: boolean;
  showTarget?: boolean;
  targetProps?: KpiTargetProps | null;
  trendProps?: TrendlineProps | null;
  granularity?: MhcTimeSeriesGranularityEnum;
  unit?: MhcStatUnitEnum | null;
  viewMoreDescription?: string | null;
  viewMoreShowWhenSuppressed?: boolean;
  viewMoreUrl?: string | null;
  additionalTitleContentElement?: ReactElement;
}

export interface KpiProps extends Pick<BoxProps, "sx"> {
  id: string;
  title: string | ReactElement;
  value: number | string | ReactNode | null;
  additionalInformation?: ReactNode | null;
  enhancement?: EnhancedKpiProps;
  fillContainer?: boolean;
  info?: React.ReactChild | null;
  percentSentenceProps?: KpiPercentSentenceProps | null;
  rawValue?: number | null;
  suppressed?: boolean;
  statCaption?: string | null;
  valueSize?: KpiValueSize;
  appendChild?: ReactNode;
  relatedKpis?: KpiProps[];
  ageAdjusted: boolean;
  isPercent?: boolean;
  si?: MhcStatIdentifierFragment | null;
  index?: number;
}

export const defaultKpiStyle = (isSuppressed = false): BoxProps["sx"] => ({
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  p: 2,
  borderRadius: "4px",
  border: isSuppressed ? undefined : "1px solid #E0E0E0",
  backgroundColor: isSuppressed ? "#F8F8F8" : undefined,
  gap: 0
});

export const KPI: React.FC<KpiProps> = ({
  id,
  additionalInformation,
  enhancement = {},
  fillContainer = false,
  info,
  percentSentenceProps,
  suppressed,
  sx = {},
  title,
  valueSize,
  appendChild,
  ageAdjusted,
  index,
  isPercent: _isPercent,
  ...valueData
}) => {
  const isMobile = useIsMobile();
  const { value } = valueData;
  const isSuppressed = suppressed || value === null || value === undefined;
  const htmlId = toHtmlId(title);
  const hasDate = !!enhancement.dataDatePeriod;
  const _valueData: KpiValueAndTrendProps = { ...valueData, suppressed };
  const widthOverideSx = (fillContainer ? { width: "100%" } : {}) as BoxProps["sx"];
  const mySx = {
    ...defaultKpiStyle(isSuppressed),
    ...sx,
    ...widthOverideSx
  };
  const showTarget = enhancement?.showTarget ?? false;

  const isPercent =
    _isPercent ?? typeof title === "string"
      ? `${title.toString()}`.toLowerCase().includes("percent")
      : value?.toString().includes("%");

  const {
    targetProps,
    viewMoreShowWhenSuppressed,
    viewMoreDescription,
    viewMoreUrl,
    additionalVisualizationProps,
    dataDatePeriod
  } = enhancement;

  const locationName = enhancement?.geography ?? enhancement?.locationName;
  let startDate, endDate;
  if (enhancement?.trendProps?.timeSeries) {
    const dates = getStartAndEndDatesFromTimeSeries({
      timeSeries: enhancement?.trendProps?.timeSeries as MhcTimeSeries
    });
    startDate = dates.startDate;
    endDate = dates.endDate;
  }

  return (
    <KpiWrapper mySx={mySx} index={index} identifier={id}>
      <Box sx={{ display: "flex", gap: 0, flexDirection: "column" }}>
        <KpiGeography locationName={locationName} htmlId={htmlId} />
        <KpiTitle
          id={id}
          title={title}
          info={info}
          isSuppressed={isSuppressed}
          htmlId={htmlId}
          addMargin={!enhancement && !hasDate}
          additionalContentElement={enhancement?.additionalTitleContentElement}
          ageAdjusted={ageAdjusted}
          isPercent={isPercent}
        />
        <KpiDatePeriod datePeriod={dataDatePeriod} htmlId={htmlId} />
      </Box>
      <KpiValueAndTrend
        size={valueSize}
        enhancement={enhancement}
        htmlId={htmlId}
        {..._valueData}
        kpiFillsContainer={fillContainer}
      />
      {percentSentenceProps && <KpiPercentSentence {...percentSentenceProps} />}
      {showTarget && targetProps && (
        <KpiTarget
          {...targetProps}
          kpiFillsContainer={fillContainer}
          locationName={locationName}
          dateRange={startDate && endDate ? [startDate, endDate] : undefined}
        />
      )}
      {!isSuppressed ? (
        <>
          <KpiAdditionalInformation information={additionalInformation} />
          <KpiAdditionalVisualization
            indicatorName={typeof title === "string" ? title : ""}
            {..._valueData}
            {...additionalVisualizationProps}
          />
        </>
      ) : (
        <KpiSuppression />
      )}
      {(!isSuppressed || viewMoreShowWhenSuppressed) && (
        <KpiLink
          url={viewMoreUrl}
          description={viewMoreDescription}
          addMargin={!hasDate}
          onClick={() => {
            sendGaNavigationEvent({
              category: "KPI",
              action: "View more click",
              label: typeof title === "string" ? title : "",
              ui_location: window.location.pathname
            });
          }}
        />
      )}
      {isMobile && isAgeAdjusted({ id, ageAdjusted, isPercent }) && <KpiAgeAdjustedPopover />}
    </KpiWrapper>
  );
};
